import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { RequestsInDashboard } from '../models/dashboard';
import { environment } from 'src/environments/environment';
import { firstValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DashboardService {

  constructor(private httpClient: HttpClient) { }

  configUrlStatus = environment.apiUrl + "/dashboard/status";
  configUrlDashboard = environment.apiUrl + "/dashboard/GetRequestsV2Batch";
  configUrlRequests = environment.apiUrl + "/dashboard/GetRequestsV2";
  configUrlActions = environment.apiUrl + "/dashboard/myactions";
  configUrlOrganisationdocuments = environment.apiUrl + "/dashboard/GetRequestsForOrganisationV2Batch";
  // FUTURE URLS, BUT NOT YET NEEDS NEW BACKEND
  // configUrlDashboard = environment.apiUrl + "/dashboard";
  // configUrlOrganisationdocuments = environment.apiUrl + "/dashboard/ForOrganisation";

  async getDashboard() {
    let params = new HttpParams().set('includeExpired', true)
    return firstValueFrom(this.httpClient.get<RequestsInDashboard>(this.configUrlDashboard, { params }));
  }

  async getRequestsFromOrg() {
    let params = new HttpParams().set('includeExpired', true);
    return firstValueFrom(this.httpClient.get<RequestsInDashboard>(this.configUrlOrganisationdocuments, { params }));
  }

}
