import { Component, EventEmitter, OnDestroy, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ViewerDataService } from '../../viewer-data.service';
import { DocumentApiClient } from 'src/app/services/document.apiclient';
import { DocumentForDownloadModel } from 'src/app/models/api/document-for-download.model';
import { DownloadService } from 'src/app/services/download.service';
import { ViewerHubService } from 'src/app/services/viewerhub.service';
import { Subscription } from 'rxjs';
import { ToastService } from 'src/app/services/toast.service';

@Component({
  templateUrl: './display-request-completed.modal.html'
})
export class DisplayRequestcompletedModal implements OnDestroy {

  @Output('DownloadRequest') DownloadRequest = new EventEmitter();

  dossierStatus!: string;
  requestId!: string;
  documentsToShow: DocumentForDownloadModel[] = [];
  masterToggle: boolean = true;
  selected: DocumentForDownloadModel[] = [];
  greeting!: string;
  date: string = "";
  expires!: string;
  showDcGenerateMessage: boolean = false;
  /**
   * A count for whether NEW documents have been added to the request
   * if YES, just reget the download list
   */
  private showingDocumentsTelling = 0;
  private requestDocsListener: Subscription;

  constructor(public activeModal: NgbActiveModal, private viewerDataService: ViewerDataService,
    private viewerHubService: ViewerHubService,
    private toastService: ToastService,
    private downloadService: DownloadService, private documentApiClient: DocumentApiClient) {
    this.requestId = this.viewerDataService.get_RequestGuid;
    this.dossierStatus = this.viewerDataService.get_RequestStatus;

    this.retrieveMyDocumentsForDisplay();
    // If generateDC just to be sure, show DC message:
    // ONLY for this specific MODAL
    if (this.viewerDataService.generateDC) {
      this.showDcGenerateMessage = true;
      let verifyNewDocumentInterval = setInterval(() => {
        // is the spinenr still active?
        if (!this.showDcGenerateMessage) {
          clearInterval(verifyNewDocumentInterval);
          return;
        }
        this.showDcGenerateMessage = false;
        this.toastService.showErrorByKeyNoAutoHide("Toasts.ErrorGeneratingDC");
        // stop this timer
        clearInterval(verifyNewDocumentInterval);
      }, 60000);
    }
    this.requestDocsListener = this.viewerHubService.viewerRequest$.subscribe(request => {
      if (request && request.documentInfo) {
        this.retrieveMyDocumentsForDisplay();
      }
    });

  }
  ngOnDestroy(): void {
    if (this.requestDocsListener) {
      this.requestDocsListener.unsubscribe();
    }
  }
  get anyDocumentsSelected(): boolean {
    return this.documentsToShow && this.documentsToShow.some(x => x.isSelected);
  }

  downloadRequest() {
    var documentsToDownload = this.documentsToShow.filter(model => model.isSelected);
    this.downloadService.download(this.requestId, documentsToDownload);
  }

  toggleAll() {
    this.masterToggle = !this.masterToggle
    this.documentsToShow.forEach((doc) => {
      doc.isSelected = this.masterToggle;
    });
  }
  toggle(newModel: DocumentForDownloadModel) {
    newModel.isSelected = !newModel.isSelected;
    if (this.masterToggle)
      this.masterToggle = !this.masterToggle
  }
  private retrieveMyDocumentsForDisplay() {
    this.documentApiClient.getForDownloadList(this.requestId).subscribe((data) => {
      this.documentsToShow = data;
      if (this.documentsToShow) {
        // only on second time and if NEW documents
        if (this.showingDocumentsTelling > 0 && this.showingDocumentsTelling < this.documentsToShow.length) {
          this.showDcGenerateMessage = false;
        }
        this.showingDocumentsTelling = this.documentsToShow.length;
      }
    });
  }
}
